
import { Nullable } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { optionsChart } from '@/utils/generateOpionsChart'
import { ApexOptions } from 'apexcharts'

interface ApexSeriesInterface {
  name?: string
  color?: string
  data: Nullable<number>[]
}

export interface ChartSeriesInterface extends ApexSeriesInterface {
  total: Nullable<number>
}

export type ChartType = 'line' | 'area'

@Component({
  name: 'Chart',
})
export default class Chart extends Vue {
  @Prop()
  readonly classHead!: string

  @Prop({ required: true })
  readonly id!: string

  @Prop({ required: true })
  readonly title!: string

  @Prop({ default: 'area' })
  readonly type!: ChartType

  @Prop({ default: '100%' })
  readonly width!: string

  @Prop({ default: 'auto' })
  readonly height!: string

  @Prop({ required: true })
  readonly series!: ChartSeriesInterface[]

  @Prop({ required: true })
  readonly keys!: string[]

  get options(): ApexOptions {
    return optionsChart(this.id, this.keys)
  }
}
